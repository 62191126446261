import React, { useRef, useEffect } from "react"
import Img from "gatsby-image"
import { useSelector } from "react-redux"
import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import { initVimeoPlayerWithControl } from "../../utils"
import CTABtn from "../../Button/CTA"
import Explore from "../Home/Explore"
import Join from "../Home/Join"
import Car from "../../../images/Articles/Drivecation 2/car.png"
import Clock from "../../../images/Articles/Drivecation 2/clock.png"
import Pin from "../../../images/Articles/Drivecation 2/pin.png"

export default function Drivecation({ sanity }) {
  const isChinese = useSelector(state => state.lang.locale) === "tc"

  gsap.registerPlugin(ScrollToPlugin)
  const handleClick = () => {
    if (typeof window !== "undefined") {
      gsap.to(window, {
        duration: 1,
        scrollTo: { y: "#join-section" },
      })
    }
  }
  return (
    <div>
      <SectionVideoIntro />
      <SectionIntro isChinese={isChinese} data={sanity} />
      <SectionImage data={sanity.articleimg2.childImageSharp.fluid} />
      <SectionBlock
        isChinese={isChinese}
        time={isChinese ? "15:00 展開悠閒住宿假期" : "15:00 Check in at Hotel"}
        location={isChinese ? "香港嘉里酒店" : "Hong Kong Kerry Hotel"}
      >
        <p className="light-font xxl:text-5xl text-4xl">
          {isChinese
            ? "準備開展由BMW領航的自駕體驗，入住香港嘉里酒店，盡情享受室外無邊際泳池和健身房設施，欣賞維多利亞港一覽無遺的明媚風光!"
            : "Get prepared to start a self-driving experience led by BMW. Enjoy the staycation moment and all the hotel facilities including the infinity pool and health and beauty treatments with your loved one in the Kerry Hotel."}
        </p>
      </SectionBlock>
      <SectionTips
        title={isChinese ? "THE 3 Touring 貼心位" : "Tips from THE 3 Touring"}
        paragraph={
          isChinese
            ? "車廂配備可獨立開啟的後車窗，讓你輕鬆方便攜帶重物出行。"
            : "Equip your holiday staycation with flexible luggage compartments, allowing you to store even bulky items that you want to take with you."
        }
      />
      <SectionImageFull data={sanity.articleimg3.childImageSharp.fluid} />
      <SectionBlock
        isChinese={isChinese}
        time={
          isChinese ? "16:00 欣賞無邊際絕美景觀" : "16:00 Indulge Your Senses"
        }
        location={isChinese ? "無邊際泳池" : "The Infinity Pool"}
      >
        <p className="light-font xxl:text-5xl text-4xl">
          {isChinese
            ? "在酒店戶外無邊際泳池，一邊欣賞維多利亞景色，一邊放鬆暢泳。滿足你的心靈所需，回歸基本意念。"
            : "Take a dip in the outdoor Infinity Pool while admiring the view of Victoria Harbour and Hong Kong Island. Enjoy the quality moment and revitalise your spirit."}
        </p>
      </SectionBlock>
      <SectionImage data={sanity.articleimg4.childImageSharp.fluid} />
      <SectionBlock
        isChinese={isChinese}
        time={
          isChinese
            ? "19:00 融合經典與現代元素"
            : "19:00 Enjoy the Asian Flavours"
        }
        location={isChinese ? "紅糖餐廳" : "Hung Tong"}
      >
        <p className="light-font xxl:text-5xl text-4xl">
          {isChinese
            ? "餐廳菜式結合經典粵菜精髓與新派烹飪概念。而裝潢則以昔日紅磡面貌為靈感，讓你與摯愛一邊眺望維多利亞港及港島醉人景緻，一邊享受尊貴用餐體驗。"
            : "Hung Tong, where classic Cantonese cuisine meets contemporary cooking concept. Its design is inspired by the historical heritage of Hung Hom. With the views over Victoria Harbour and Hong Kong Island, it allows you and your loved one to enjoy the contemporary Chinese dining experience in an intimate space."}
        </p>
      </SectionBlock>
      <SectionImage data={sanity.articleimg5.childImageSharp.fluid} />
      <SectionBlock
        isChinese={isChinese}
        time={
          isChinese ? "8:00 放鬆心情開展全新一天" : "8:00 Rejuvenate Your Mind"
        }
        location={isChinese ? "豪華客房" : "Deluxe City View Room"}
      >
        <p className="light-font xxl:text-5xl text-4xl">
          {isChinese
            ? "以一杯咖啡和BMW朱古力蛋糕開展新的一天。"
            : "Start a new day with a cup of coffee and a slice of BMW chocolate cake."}
        </p>
      </SectionBlock>
      <SectionImage data={sanity.articleimg6.childImageSharp.fluid} />
      <SectionBlock
        isChinese={isChinese}
        time={isChinese ? "9:00 尋找健身樂趣" : "9:00 Stay Active"}
        location={isChinese ? "健身中心" : "The Gym"}
      >
        <p className="light-font xxl:text-5xl text-4xl">
          {isChinese
            ? "早上到酒店的健身中心做運動，配備先進齊全。落地玻璃的設計把自然日光引進室內，讓你可以一邊飽覽香港的迷人景致，一邊盡情鍛鍊，保持健康活力。"
            : "Hit the scenic gym with a view overlooking the vibrant Hong Kong island skyline in the morning! The fitness center is equipped with a wide variety of training equipment that keep you refreshed and healthy."}
        </p>
      </SectionBlock>
      <SectionImageFull data={sanity.articleimg7.childImageSharp.fluid} />
      <SectionBlock
        isChinese={isChinese}
        time={isChinese ? "10:30 進入平靜的境界" : "10:30 Soothe Your Body"}
        location={isChinese ? "水療中心" : "The Spa"}
      >
        <p className="light-font xxl:text-5xl text-4xl">
          {isChinese
            ? "水療中心設計採用深色木材及石頭，散發著濃厚大自然氣色。療程讓你與最愛遠離緊張的城市節奏，放鬆繃緊情緒，學懂享受生活中的每分每秒。"
            : "The design in the Spa integrates the rich natural textures of dark woods and stone, and creates a relaxing atmosphere for you and your loved one. Escape from the fast-paced city life and take a break."}
        </p>
      </SectionBlock>
      <SectionImage data={sanity.articleimg8.childImageSharp.fluid} />
      <SectionBlock
        isChinese={isChinese}
        time={
          isChinese
            ? "12:00 激發創意靈感"
            : "12:00 Enjoy An Artisanal Experience"
        }
        location={
          isChinese
            ? "Platform Art Jamming Studio HK"
            : "Platform Art Jamming Studio HK"
        }
      >
        <p className="light-font xxl:text-5xl text-4xl">
          {isChinese
            ? "離開酒店後，出發到車程距離大約20分鐘的自助畫室。"
            : "After checking out of the hotel, with only around 25 minutes' driving distance, you will arrive at the art jamming studio!"}
        </p>
      </SectionBlock>
      <SectionImage data={sanity.articleimg9.childImageSharp.fluid} />
      <SectionTips
        title={isChinese ? "THE 3 Touring 貼心位" : "Tips from THE 3 Touring"}
        paragraph={
          isChinese
            ? "BMW Intelligent Personal Assistant 記錄你的個人決定和設定，控制導航功能，助你輕鬆展開旅程。"
            : "BMW Intelligent Personal Assistant notes your individual decisions and settings, controls navigation functions and assists you on your journey."
        }
      />
      <SectionImageFull data={sanity.articleimg10.childImageSharp.fluid} />
      <SectionParagraph>
        <p className="light-font xxl:text-5xl text-4xl">
          {isChinese
            ? "畫室提供一切繪畫所需的設備，加上寬敞的空間和光線充足的開揚落地玻璃，營造悠然舒適的環境，讓你與至愛盡情放鬆，享受Art Jamming的滿足感和樂趣。"
            : "The studio provides you with everything you need for painting. With the spacious space and the floor-to-ceiling glass wall that let in natural light, you can enjoy the artistic moment with your loved one!"}
        </p>
      </SectionParagraph>
      <SectionImage data={sanity.articleimg11.childImageSharp.fluid} />
      <SectionTips
        title={isChinese ? "THE 3 Touring 貼心位" : "Tips from THE 3 Touring"}
        paragraph={
          isChinese
            ? "配備能獨立開閉的後車窗，令你卸下較小的行李時尤其實用，無需打開整個尾門。"
            : "Rear window can be opened separately. It is practical for stowing or removing smaller pieces of luggage without having to open the entire tailgate."
        }
      />
      <section className="md:my-20 md:py-20 bg-brand-light-grey py-40 my-40">
        <div className="article-container">
          <div className="flex justify-center">
            <div className="md:w-full w-10/12 px-12">
              {isChinese ? (
                <div class="text-center">
                  <h3 class="title md:text-4xl bold-font text-center leading-relaxed md:leading-normal md:mt-20 mb-10 xxl:mb-20">
                    THE JOURNEY 悅程匯會員可享下列多項獨家優惠，
                    <button
                      className="font-bold underline"
                      onClick={handleClick}
                    >
                      立即加入
                    </button>
                    ：
                  </h3>
                  <p className="large-text">
                    BMW X Kerry Hotel 住宿假期
                    <br />
                    Platform Art Jamming Studio 優惠
                  </p>
                </div>
              ) : (
                <div class="text-center">
                  <h3 class="title md:text-4xl bold-font text-center leading-relaxed md:leading-normal md:mt-20 mb-10 xxl:mb-20">
                    THE JOURNEY enjoys exclusive promotions and offers -{" "}
                    <button
                      className="font-bold underline"
                      onClick={handleClick}
                    >
                      JOIN NOW
                    </button>
                    :
                  </h3>
                  <p className="large-text">
                    BMW X Kerry Hotel Staycation Offer
                    <br />
                    Platform Art Jamming Studio Offer
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <SectionQuotes>
        {isChinese
          ? "BMW與您一同展開本地自駕體驗，敢於嘗新，敢於闖盪。開展冒險之旅，創造生活新旅途。"
          : "Explore local Drivecations for an enriching self-driving journey Along with BMW. Be bold, try something new."}
      </SectionQuotes>
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn />
    </div>
  )
}

function SectionVideoIntro() {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayerWithControl({
        selector: video.current,
        desktopVid: "https://vimeo.com/441273710/07bafe383f",
        mobileVid: "https://vimeo.com/441273710/07bafe383f",
      })
  }, [])

  return (
    <section>
      <div ref={video}></div>
    </section>
  )
}

function SectionIntro({ isChinese, data }) {
  return (
    <section className="md:py-20 py-40">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="md:w-full w-10/12 px-12">
            <h2 className="quotes md:text-5xl bold-font md:mb-20 mb-10">
              Drivecation Vol.2：THE 3 Touring x A Couples Retreat Time
            </h2>
            <p className="large-text md:mb-12 mb-20">
              <b className={isChinese ? "bold-font font-bold" : ""}>
                {isChinese
                  ? "今個秋季出行，由BMW帶你尋找香港好去處"
                  : "BMW will take you to different places in Hong Kong and enjoy local adventures."}
              </b>
              <span className="light-font">
                {isChinese
                  ? "，今集 Vol.2將帶你與BMW THE 3 Touring 遠離城市繁囂，到訪香港嘉里酒店盡情放鬆並享用奢華體驗，一同展開一趟舒適的城中度假。"
                  : "In this episode, BMW connects you with the BMW THE 3 Touring and enjoys a getaway within the city. Take time out with your loved one and visit the Kerry Hotel. Relax and enjoy a luxurious staycation experience."}
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionImage({ data }) {
  return (
    <section className="md:pt-20 pt-40">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="md:w-full w-10/12 px-12">
            <div className="image-container">
              <Img fluid={data} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionBlock({ children, time, location }) {
  return (
    <section className="md:py-20 py-40">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="md:w-full w-10/12 px-12">
            <div className="md:flex-col flex">
              <div className="md:w-full md:pr-0 w-1/2 pr-20">
                <div className="xxl:text-5xl flex mb-10 text-4xl">
                  <div className={`w-16 mr-6 -mt-4 xxl:-mt-2 self-center`}>
                    <img src={Clock} alt="" className="w-full" />
                  </div>
                  <p className="bold-font font-bold">{time}</p>
                </div>
                <div className="xxl:text-5xl flex text-4xl">
                  <div className={`w-16 mr-6 -mt-4 xxl:-mt-2 self-center`}>
                    <img src={Pin} alt="" className="w-full" />
                  </div>
                  <p className="bold-font font-bold">{location}</p>
                </div>
              </div>
              <div className="md:w-full md:pl-0 w-1/2 pl-20">
                <div className="flex flex-col">
                  <hr className="md:w-full md:opacity-0 md:mb-10 w-40 h-2 mb-32 bg-black" />
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionTips({ paragraph, title }) {
  return (
    <section className="md:my-20 md:py-20 bg-brand-light-grey py-40 my-40">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="md:w-full w-10/12 px-12">
            <header className="section-header flex justify-center mb-20 text-center">
              <div className={`w-20 md:w-12 mr-6 md:mr-4 self-center`}>
                <img src={Car} alt="" className="w-full" />
              </div>
              <h3 className="quotes quotes--sm md:text-4xl bold-font self-center">
                {title}
              </h3>
              <div className={`w-20 md:w-12 ml-6 md:ml-4 self-center`}>
                <img src={Car} alt="" className="w-full" />
              </div>
            </header>
            <p className="large-text light-font text-center">{paragraph}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionImageFull({ data }) {
  return (
    <section className="md:py-20 py-40">
      <Img fluid={data} />
    </section>
  )
}

function SectionParagraph({ children }) {
  return (
    <section className="">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="md:w-full w-10/12 px-12">{children}</div>
        </div>
      </div>
    </section>
  )
}

function SectionQuotes({ children }) {
  return (
    <section className="md:my-20 md:py-20 py-40 my-40">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="md:w-full w-10/12 px-12">
            <div class="text-center">
              <div class="flex justify-center">
                <hr class="w-64 md:w-full h-1 bg-black mt-32 mb-20 md:hidden" />
              </div>
              <h3 class="quotes--sm md:text-4xl bold-font text-center leading-relaxed md:leading-normal md:mt-20">
                {children}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

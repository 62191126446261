import React from "react"
import styles from "./index.module.scss"

export default function index() {
  return (
    <svg viewBox="0 0 512 512">
      <path d="M493.195,233.93933l.01159-.01355L440,188.32019V129.94092a31.78981,31.78981,0,0,0-9.37256-22.627L348.686,25.37256A31.78981,31.78981,0,0,0,326.05908,16H104A32.036,32.036,0,0,0,72,48V188.32019L18.79346,233.92578l.01159.01355A7.98651,7.98651,0,0,0,16,240V488a7.99977,7.99977,0,0,0,8,8H488a7.99977,7.99977,0,0,0,8-8V240A7.98651,7.98651,0,0,0,493.195,233.93933ZM72.35168,280l90.9148,57.42L32,468.68652V254.51465l40,25.26318V280ZM160,128a24.02687,24.02687,0,0,1,24-24,49.61468,49.61468,0,0,1,35.314,14.62744L232,131.31348v17.373l-19.314-19.314A31.78981,31.78981,0,0,0,190.05908,120H176v16h14.05908a15.89507,15.89507,0,0,1,11.31348,4.686L212.68652,152H184A24.02687,24.02687,0,0,1,160,128Zm169.02167-23.968a32.11866,32.11866,0,0,0,22.94641,22.94629c.01428.34033.03192.6803.03192,1.02173a24.02687,24.02687,0,0,1-24,24H299.31348l11.314-11.314A15.89507,15.89507,0,0,1,321.94092,136H336V120H321.94092a31.78981,31.78981,0,0,0-22.627,9.37256L280,148.68652v-17.373l12.686-12.686A49.61468,49.61468,0,0,1,328,104C328.34143,104,328.6814,104.01758,329.02167,104.032ZM352,316.43262,333.68506,328H288V216h64ZM240,168h40v32H232V168Zm32,48V328H240V216Zm88-16H296V168h72v32Zm-96-48H248V136h16Zm-80,16h32v32H144V168Zm40,48V328H178.31494L160,316.43262V216ZM179.31348,344h153.373l136,136H43.31348Zm169.42-6.58L439.64832,280H440v-.22217l40-25.26318V468.68652Zm125.83264-98.39782L440,260.85352V209.394ZM419.314,118.62744A15.89507,15.89507,0,0,1,424,129.94092V270.95886l-56,35.36853V216h8a7.99977,7.99977,0,0,0,8-8V160a7.99977,7.99977,0,0,0-8-8H359.97815A39.79044,39.79044,0,0,0,368,128h24V112H360a16.01833,16.01833,0,0,1-16-16V43.31348ZM104,32H326.05908A16.19666,16.19666,0,0,1,328,32.12939V88a65.50827,65.50827,0,0,0-46.62744,19.314L268.68652,120h-25.373l-12.686-12.686A65.50827,65.50827,0,0,0,184,88a39.97818,39.97818,0,0,0-31.97815,64H136a7.99977,7.99977,0,0,0-8,8v48a7.99977,7.99977,0,0,0,8,8h8v90.32739L88,270.95886V48A16.01833,16.01833,0,0,1,104,32ZM72,260.85352l-34.56616-21.8313L72,209.394Z" />
    </svg>
  )
}

import React, { Component, useState, useRef, useEffect } from "react"
import BackgroundImage from "gatsby-background-image"
import { StaticQuery, graphql } from "gatsby"
import styles from "./index.module.scss"
import Fade from "react-reveal/Fade"
import _ from "lodash"
// import { useLocation } from "@reach/router"
// import queryString from "query-string"
import loadStates from "../../../../hocs/loadStates"

class Join extends Component {
  constructor() {
    super()

    this.form = null
    this.throttleResizeEvent = _.throttle(() => this.setMarginBottom(), 500)
    this.state = {
      formHeight: 0,
    }
  }
  componentDidMount() {
    if (
      typeof window !== "undefined" &&
      window.innerWidth <= 768 &&
      this.form
    ) {
      this.setMarginBottom()
      window.addEventListener("resize", this.throttleResizeEvent, false)
    }
  }

  componentWillUnmount() {
    this.form &&
      window.removeEventListener("resize", this.throttleResizeEvent, false)
  }

  setMarginBottom = () => {
    if (window.innerWidth <= 768) {
      this.setState({ formHeight: this.form.scrollHeight - 1 })
    } else {
      this.setState({ formHeight: 0 })
    }
  }
  render() {
    const {
      home,
      URL: { queryString },
    } = this.props
    const { locale } = this.props.lang
    const isChinese = locale === "tc"
    // const queryString = typeof window !== "undefined" && window.location.search
    // const iframeBaseURL = isChinese
    //   ? "https://sdbmwhk.secure.force.com/bmwhkv3?cm=AWB_teaser_2020&lang=tc"
    //   : "https://sdbmwhk.secure.force.com/bmwhkv3?cm=AWB_teaser_2020"
    // const iframeURL = queryString
    //   ? iframeBaseURL + "&" + queryString.split("?")[1]
    //   : iframeBaseURL

    return (
      <section
        id="join-section"
        style={{ marginBottom: this.state.formHeight }}
        className={`${styles.section} section-home-join h-screen relative pl-32 md:px-12 md:pt-20`}
      >
        <div className="flex h-full">
          <div className="w-full text-white h-full">
            <div className="flex h-full justify-between md:flex-col md:relative">
              <div className="w-7/12 xxl:w-5/12 flex pr-20 md:pr-0 md:w-full">
                <div
                  ref={form => (this.form = form)}
                  className={`${styles.formContainer} relative pt-12 px-16 w-full self-center md:px-0 md:absolute md:left-0`}
                >
                  <Fade>
                    {/* <div>
                      {console.log(iframeURL)}
                      {console.log(queryString)}
                      <iframe
                        src={iframeURL}
                        frameBorder="0"
                        className={`w-full ${styles.form}`}
                      ></iframe>
                    </div> */}
                    <IframeForm
                      isChinese={isChinese}
                      queryString={queryString}
                    />
                  </Fade>
                </div>
              </div>
              <div className="w-5/12 flex h-full md:w-full">
                <Fade>
                  <div className="self-end">
                    <h3 className="headline uppercase mb-4">
                      {home.joinSectionTitle}
                    </h3>
                    <div className="mb-16 pr-20 md:pr-0 md:mb-24">
                      {isChinese ? (
                        <>
                          <span className="large-text">
                            人人均可啓動心悅旅程
                          </span>
                          <ul className="mt-10">
                            只須簡單登記加入The Journey 悅程匯，即可尊享：
                            <li className="list-disc">
                              優先獲邀參加一系列BMW全方位體驗活動
                            </li>
                            <li className="list-disc">
                              獲取最新BMW
                              Drivecation本地駕駛旅程資訊，更有機會贏得與合作品牌打造的獨家禮遇
                            </li>
                            <li className="list-disc">
                              BMW其他品牌活動優先邀請及第一手獨家優惠資訊
                            </li>
                            <li className="list-disc">
                              緊貼此平台最新動態，一覽BMW獨家車主故事及一系列產品冷知識。
                            </li>
                          </ul>
                        </>
                      ) : (
                        <>
                          THE ROAD IS FOR EVERYONE
                          <ul className="mt-10">
                            Simply register now to get below exclusive
                            privileges:
                            <li className="list-disc">
                              Receive priority-invitations to join our series of
                              All-new BMW Experiences
                            </li>
                            <li className="list-disc">
                              Stay up-to-date with our latest curation of BMW
                              Drivecations and have the chance to win exclusive
                              gifts created with BMW partnered brands
                            </li>
                            <li className="list-disc">
                              Priority-invitations to join BMW activations and
                              get first-hand news on exclusive promotions
                            </li>
                            <li className="list-disc">
                              Get notified about our latest exclusive BMW-owner
                              stories and insider product information
                            </li>
                          </ul>
                        </>
                      )}
                      {/* {home.joinSectionParagraph.map((paragraph, i) => (
                        <>
                          {i === 0 ? (
                            ""
                          ) : i === 2 ? (
                            ""
                          ) : (
                            <br className="mb-10" />
                          )}
                          {paragraph}
                        </>
                      ))} */}
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`bg-container absolute w-full h-full top-0 left-0 -z-10 ${styles.bgContainer}`}
        >
          <StaticQuery
            query={graphql`
              query JoinQuery {
                desktop: file(name: { eq: "join_desktop" }) {
                  childImageSharp {
                    fluid(maxWidth: 3080, quality: 50) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                mobile: file(name: { eq: "join_mobile" }) {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            `}
            render={data => (
              <>
                <BackgroundImage
                  Tag="div"
                  className="w-full h-full md:hidden"
                  fluid={data.desktop.childImageSharp.fluid}
                  backgroundColor={`#040e18`}
                  loading="eager"
                  critical={true}
                />
                <BackgroundImage
                  Tag="div"
                  className="w-full h-full hidden md:block"
                  fluid={data.mobile.childImageSharp.fluid}
                  backgroundColor={`#040e18`}
                  loading="eager"
                  critical={true}
                />
              </>
            )}
          />
        </div>
      </section>
    )
  }
}

export default loadStates(Join)

function IframeForm({ isChinese, queryString }) {
  // const location = useLocation()
  const form = useRef(null)

  useEffect(() => {
    const iframeBaseURL = isChinese
      ? "https://sdbmwhk.secure.force.com/bmwhkv3?cm=AWB_teaser_2020&lang=tc"
      : "https://sdbmwhk.secure.force.com/bmwhkv3?cm=AWB_teaser_2020"

    form.current.setAttribute(
      "src",
      `${iframeBaseURL}${queryString ? `&${queryString.split("?")[1]}` : ""}`
    )
  })

  return (
    <>
      <iframe
        ref={form}
        frameBorder="0"
        className={`w-full ${styles.form}`}
      ></iframe>
    </>
  )
}

import React, { Component } from "react"
import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import styles from "./index.module.scss"
import EmailIcon from "../../Icons/Email"
import loadStates from "../../../hocs/loadStates"
import { Link } from "gatsby"

class CTABtn extends Component {
  handleClick = e => {
    if (typeof window !== "undefined") {
      gsap.to(window, {
        duration: 1,
        scrollTo: { y: `#join-section` },
      })
      // if (window.innerWidth > 768) {
      //   TweenMax.to(window, 1, {
      //     scrollTo: "#join-section",
      //     ease: Sine.easeInOut,
      //   })
      // } else {
      //   smoothscroll.polyfill()
      //   document.querySelector("#join-section").scrollIntoView({
      //     behavior: "smooth",
      //   })
      // }
    }
  }
  render() {
    const { locale } = this.props.lang
    const isChinese = locale === "tc"
    return (
      <div
        className={`${styles.btn} fixed bottom-0 right-0 mr-10 mb-10 border-black border-solid border-2 cursor-pointer z-50`}
        onClick={this.handleClick}
      >
        <div
          className={`${styles.expandable} absolute h-full flex border-black border-solid border-2 border-l-none`}
        >
          <div
            className={`${styles.btnText} absolute body self-center opacity-0 pl-8 xxl:text-3xl`}
          >
            {isChinese ? "立即加入The Journey" : "Join The Journey Now"}
          </div>
          <div className="absolute -z-10 w-full h-full top-0 left-0 bg-white opacity-75"></div>
        </div>
        <div className="h-full relative flex p-4">
          <div className="self-center w-16 mt-2">
            <EmailIcon />
          </div>
          <div className="absolute -z-10 w-full h-full top-0 left-0 bg-white opacity-75"></div>
        </div>
      </div>
    )
  }
}

export default loadStates(CTABtn)
